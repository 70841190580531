import { FC, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Input,
  InputSize,
  Label,
  Message,
  ToggleSwitch,
} from '../../../../components';
import { cx, inputClasses } from '../../../../utils';
import { IProperty } from '../../interface';
//@ts-ignore
import Icon from '@gjirafatech/gjirafa-icons/Icon';
interface IOptionProperties {
  properties: IProperty[];
  registerName: string;
  errors?: any;
}
export const OptionProperties: FC<IOptionProperties> = ({
  properties,
  registerName,
  errors,
}) => {
  const { register, control } = useFormContext();

  const compoundEntryIState: { [key: string]: string } = {};
  useEffect(() => {
    properties?.forEach((property: IProperty) => {
      compoundEntryIState[property.name] = '';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  const isPropertySpecialType = (property: IProperty) => {
    return properties && (property?.inputType?.name === 'DateTime' || property?.inputType?.name === 'Boolean')
  }

  return (
    <>
      <div className="mb-2">
        {properties?.map((property: IProperty, colIndex: number) => {
          const columnName = property?.name
          const error = errors?.propertyValues
          return (
            <div className="flex-1 mb-4" key={colIndex}>
              {!isPropertySpecialType(property) && (
                  <>
                    <Label
                      text={property?.name}
                      required={property?.isRequired}
                    />
                    <Input
                      {...register(
                        `${registerName}.${columnName}`,
                        property?.isRequired
                          ? {
                              required: `${columnName} is required!`,
                            }
                          : {}
                      )}
                      inputSize={InputSize.sm}
                      className={inputClasses}
                      placeholder={`Enter ${columnName} value`}
                      error={
                        !!error?.[columnName] && error?.[columnName]?.message
                      }
                    />
                  </>
                )}
              {property?.inputType?.name === 'Boolean' && (
                <>
                  <Label
                    text={property?.name}
                    required={property?.isRequired}
                  />
                  <Controller
                    control={control}
                    name={`${registerName}.${columnName}`}
                    render={({ field: { onChange, value, ref } }) => (
                      <ToggleSwitch
                        id={`${registerName}.${columnName}`}
                        label={value}
                        description=""
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                  {error?.[columnName] && (
                    <Message message={`${columnName} is required!`} />
                  )}
                </>
              )}
              {property?.inputType?.name === 'DateTime' && (
                <>
                  <Label
                    text={property?.name}
                    required={property?.isRequired}
                  />
                  <Controller
                    control={control}
                    name={`${registerName}.${columnName}`}
                    rules={
                      property?.isRequired
                        ? {
                            required: `${columnName} is required!`,
                          }
                        : {}
                    }
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          calendarIcon={
                            <span className="inline-block w-5 h-5">
                              <Icon name="Calendar" size={18} />
                            </span>
                          }
                          className={cx([
                            "w-full bg-white focus:shadow-primary test",
                            error?.[columnName] && "react-date-picker--invalid"
                          ])}
                          onChange={onChange}
                          value={value || null}
                          dayPlaceholder="DD"
                          monthPlaceholder="MM"
                          yearPlaceholder="YYYY"
                        />
                      );
                    }}
                  />
                  {error?.[columnName] && (
                    <Message message={`${columnName} is required!`} />
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
