import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';
import {
  BackButton,
  Container,
  FormFooter,
  PageHeadeline,
} from "../../components";
import {
  MODELS_PATH, USER_PROFILE_API, USER_PROFILE_API_HEADERS
} from "../../constants";
import { useFetch } from "../../hooks";
import { ModelActions, ModelMainInfo, ModelProperties, ModelType } from "./components";
import {
  ICreateModel,
  IExpectedStatusCode,
  IProperty,
  actionIState,
  multiOptionPropertiesIState,
  propertiesIState
} from "./interface";
import { handleErrors } from './utils/handle-errors';

export const ModelCreate = () => {
  const navigate = useNavigate();
  const { apiCall: createModel, loading } = useFetch("post");
  const methods = useForm({
    defaultValues: {
      alias: "",
      name: "",
      iconField: "",
      description: "",
      modelType: 'standard',
      properties: propertiesIState,
      multiOptionProperties: multiOptionPropertiesIState,
      actions: [actionIState],
    },
  });

  const { handleSubmit, control } = methods;

  const [modelType] = useWatch({
    control,
    name: ['modelType'],
  });

  const handleSubmitForm: SubmitHandler<any> = (data: ICreateModel, submitType: any) => {
    const actions = data?.actions?.map((action) => {
      const visibilitySettings = action?.visibilitySettings?.map(
        (vbSettings) => {
          return {
            propertyName: vbSettings?.property?.name,
            operator: vbSettings.operator,
            value: vbSettings.value,
          };
        }
      );

      const outputs = action?.outputs?.map((output) => {
        return {
          propertyName: output?.property?.name,
          value: output.value,
        };
      });

      const expectedSuccessStatusCodes =
        action?.expectedSuccessStatusCodes?.map(
          (statusCode: IExpectedStatusCode) => statusCode.value
        );

      return {
        ...action,
        methodType: action?.methodType?.value,
        headers: action?.actionType?.id === 2 ? action?.headers : null,
        visibilitySettings: action?.showVisibilitySettings
          ? visibilitySettings
          : null,
        outputs: action?.addActionOutput ? outputs : null,
        expectedSuccessStatusCodes,
      };
    });

    const isMultiOption = data?.modelType === 'multioption' ? true : false

    data?.properties.splice(0, 1);

    const allProperties = isMultiOption ? data?.properties.concat(data?.multiOptionProperties) : data?.properties

    const properties = allProperties?.map((property: IProperty) => {
      return {
        name: property?.name,
        inputType: property?.inputType,
        inputTypeId: property?.inputTypeId,
        designationType: property?.designationType,
        isRequired: property?.isRequired,
        childBody: property?.nested?.length > 0 ? {
          properties: property?.nested
        } : null
      }
    })

    const modelObject = {
      icon: data?.icon,
      name: data?.name,
      description: data?.description,
      modelId: data?.modelId,
      alias: data?.alias,
      isMultiOption,
      body: {
        actions: isMultiOption ? [] : actions,
        properties
      },
    };

    createModel(
      `${USER_PROFILE_API}/integration-models`,
      modelObject,
      (data) => {
        const { id } = data?.data
        toast.success("Model created successfully!");
        if (submitType === 'customize') navigate(`${MODELS_PATH}/${id}/model-options`);
        else navigate(MODELS_PATH);
      },
      (err) => {
        handleErrors(err);
      },
      USER_PROFILE_API_HEADERS
    );
  };

  return (
    <Container>
      <div>
        <BackButton to={MODELS_PATH} className="mb-4" label="Back to Models" />

        <PageHeadeline
          title="Models"
          description="Create a custom model to bring in data from an external source that you wish to display in the users' profiles."
          className="mb-10"
        />
      </div>

      <form action="">
        <FormProvider {...methods}>
          <ModelMainInfo />

          <ModelType isMultiOption={modelType === 'multioption'} />

          <ModelProperties />

          {modelType === 'standard' &&
            <ModelActions />
          }

        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit(handleSubmitForm)}
          loading={loading}
          saveBtnText='Create'
          hasOptionalDropdown={modelType === 'multioption'}
        />
      </form>
    </Container>
  );
};
