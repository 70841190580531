import { Card, CardSpace, CardStyle } from '../../components'
import { OpenInNewTab } from '../../components/Icons'
import { USER_PROFILE_MODELS_API } from '../../constants'

interface SwaggerProps {
  organizationId: string
  alias: string
}

export const Swagger: React.FC<SwaggerProps> = ({ organizationId, alias }) => {
  const SWAGGER_URL = `${USER_PROFILE_MODELS_API}/${organizationId}/${alias}/swagger/index.html`

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8"
    >
      <div className="pb-7 flex justify-end">
        <a
          className="text-primary-secText hover:text-primary-pressed inline-flex focus:shadow-outlineGray rounded w-6 h-6 items-center justify-center cursor-pointer"
          href={SWAGGER_URL}
          target="_blank"
          rel="noreferrer"
          title="Open in new tab"
        >
          <OpenInNewTab width="24" />
        </a>
      </div>

      {organizationId && alias && (
        <iframe
          title="Swagger"
          src={SWAGGER_URL}
          style={{ minHeight: 800 }}
          className="w-full"
        ></iframe>
      )}
    </Card>
  )
}
