import { useNavigate } from "react-router-dom";
import { BtnSize, BtnStyle, BtnType, Button } from "../../components/button";
import { Dropdown } from '../../pages/clients/api-resources/components';
import { cancelBtnClasses, cx, primaryBtnClasses } from "../../utils";
import { DropdownOption } from '../ui/DropdownOption';
import { Spinner, SpinnerSize } from "../ui/Spinner";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

interface IFormFooter {
  className?: string;
  onSubmit: (e: any) => void;
  disabled?: boolean;
  cancelDisabled?: boolean;
  optionalBtnLabelText?: string;
  pb?: string;
  pt?: string;
  loading?: boolean;
  noCancelButton?: boolean;
  hasOptionalButton?: boolean;
  submitBtnType?: "button" | "submit";
  onOptionalButtonClick?: () => void;
  containerSize?: string;
  mtAuto?: boolean;
  saveBtnText?: string;
  backToLink?: string;
  hasOptionalDropdown?: boolean;
  dropdownOptionOne?: string;
  dropdownOptionTwo?: string
}

export const FormFooter: React.FC<IFormFooter> = ({
  className,
  onSubmit,
  disabled,
  hasOptionalButton,
  optionalBtnLabelText,
  cancelDisabled,
  submitBtnType = "button",
  loading,
  containerSize,
  pb,
  pt,
  noCancelButton,
  onOptionalButtonClick,
  mtAuto,
  saveBtnText,
  backToLink,
  hasOptionalDropdown,
  dropdownOptionOne,
  dropdownOptionTwo
}) => {
  const navigate = useNavigate();

  const backTo = () => {
    backToLink ? navigate(backToLink) : navigate(-1);
  };

  return (
    <div
      className={cx([
        "border-t border-primary-stroke text-right z-10 flex justify-end mx-auto",
        !pt && "pt-4 sm:pt-6",
        !pb && "pb-4 sm:pb-6",
        !mtAuto ? "sm:mt-24  mt-auto" : " mt-auto",
        className,
        containerSize,
      ])}
    >
      {!noCancelButton && (
        <Button
          type="button"
          btnType={BtnType.secondary}
          btnStyle={BtnStyle.neutral}
          btnSize={BtnSize.normal}
          onClick={backTo}
          disabled={cancelDisabled}
          className={cancelBtnClasses}
        >
          Cancel
        </Button>
      )}

      {hasOptionalButton && (
        <Button
          type="button"
          btnType={BtnType.secondary}
          btnStyle={BtnStyle.neutral}
          btnSize={BtnSize.normal}
          onClick={onOptionalButtonClick}
          className={cx([cancelBtnClasses, "ml-4"])}
        >
          {optionalBtnLabelText}
        </Button>
      )}

      {!hasOptionalDropdown && <Button
        type={submitBtnType}
        onClick={onSubmit}
        btnStyle={BtnStyle.primary}
        btnSize={BtnSize.normal}
        className={cx(["ml-4 flex items-center", primaryBtnClasses])}
        disabled={disabled || loading}
      >
        {loading && (
          <Spinner
            className="mr-2"
            size={SpinnerSize.xs}
            color="text-white"
            padding="p-0"
          />
        )}
        {saveBtnText ? saveBtnText : "Save"}
      </Button>}
      {hasOptionalDropdown && <Dropdown
        left
        width="w-44"
        noPadding
        className="mt-0 self-start"
        dropdownClassName="bottom-11 left-5"
        dropdownContent={
          <>
            <DropdownOption
              label={dropdownOptionOne || 'Create'}
              disabled={loading}
              onClick={() => onSubmit('save')}
            />

            <DropdownOption
              label={dropdownOptionTwo || 'Create & Customize'}
              disabled={loading}
              onClick={() => onSubmit('customize')}
            />
          </>
        }
      >
        <div
          className={cx([
            "flex items-center whitespace-nowrap bg-primary ml-4 rounded text-sm text-white cursor-pointer border",
            primaryBtnClasses,
          ])}
        >
        {loading && (
          <Spinner
            className="mr-2"
            size={SpinnerSize.xs}
            color="text-white"
            padding="p-0"
          />
        )}
          <span className="ml-2 mr-2">{saveBtnText ? saveBtnText : "Save"}</span>
          <span className='mr-1.5 '>|</span>
          <span className="w-5 h-5 inline-block">
            <Icon name="ArrowDown" size="20" />
          </span>
        </div>
      </Dropdown>}
    </div>
  );
};
