import React, { forwardRef, InputHTMLAttributes, ReactElement } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Paragraph } from "..";
import { cx } from "../../utils";

export interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  description?: string;
  labelColor?: string;
  inputRef?: any;
  label?: string | ReactElement;
}

export const Checkbox: React.FC<ICheckbox> = forwardRef(
  (
    {
      id,
      wrapperClassName,
      description,
      inputRef,
      label,
      labelColor,
      labelClassName,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div className={cx(["checkbox-wrapper group", wrapperClassName])}>
          <label
            htmlFor={id}
            className={cx([
              "inline-flex items-center",
              !Boolean(description) && "items-center",
              labelClassName,
              !!label && 'cursor-pointer'
            ])}
          >
            <div className="relative flex items-center">
              <input
                type="checkbox"
                id={id}
                className="opacity-0 absolute h-4 w-4 text-blue-mainText cursor-pointer"
                {...props}
                ref={inputRef}
              />

              <div className="icon bg-white border text-white border-primary-tertText rounded transition w-4 h-4 flex flex-shrink-0 justify-center items-center group-hover:border-gray-600">
                <Icon size={18} name="Check" />
              </div>

              {!!Boolean(label) && (
                <p
                  className={cx([
                    "description text-sm block pb-0 ml-3 transition",
                    labelColor || "text-primary-mainText",
                  ])}
                >
                  {label}
                </p>
              )}
            </div>
          </label>
          {!!Boolean(description) && (
            <Paragraph className="pl-7" noMargin>
              {description}
            </Paragraph>
          )}
        </div>
      </>
    );
  }
);
