import { RouteProps } from "react-router-dom";
import { BreadcrumbsPropsItem } from "../components/header/Breadcrumbs";
import {
  API_RES_PATH,
  API_SCOPES_PATH,
  APPS_PATH,
  ATTACK_PROTECTION_PATH,
  AUTHENTICATION_FLOW,
  AUTHENTICATION_PASSLESS,
  AUTHENTICATION_SETTINGS_PATH,
  BOT_DETECTION_PATH,
  BRANDINGS_PATH,
  BREACHED_PASS_PATH,
  BRUTE_FORCE_PATH,
  DOMAINS_PATH,
  GLOBAL_CSS_PATH,
  INTEGRATIONS_PATH,
  IP_THROTTLING_PATH,
  I_RESOLUTION_PATH,
  I_RES_PATH,
  MANAGE_USER_DATA_DELETION_PATH,
  MODELS_CREATE_PATH,
  MODELS_PATH,
  MULTI_FACTOR_AUTH_PATH,
  M_AUDIT_LOGS,
  M_ERROR_LOGS,
  ROLES_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_LANGUAGES_PATH,
  SETTINGS_MEMBER_PATH,
  SMART_EMAILING_PATH,
  TEMPLATES_PATH,
  UI_BUILDER_PATH,
  USERS_PATH,
} from "../constants";
import { Permission } from "../context";
import {
  ApiResource,
  ApiResourceCreate,
  ApiResourceProperties,
  ApiResourceSecrets,
  ApiResources,
  ApiScope,
  ApiScopeCreate,
  ApiScopeProperties,
  ApiScopes,
  Application,
  ApplicationClaims,
  ApplicationProperties,
  ApplicationSecrets,
  Applications,
  AttackProtection,
  AuditLogs,
  AuthFlow,
  AuthSettings,
  BotDetection,
  Branding,
  BrandingCreate,
  Brandings,
  BreachPassDetection,
  BruteForceProtection,
  CostumizationTemplate,
  Dashboard,
  DataImport,
  Domain,
  Domains,
  EditLanguageKeys,
  EmailProviders,
  ErrorLogs,
  GeneralSettings,
  GlobalCSS,
  IdentityRescourceProperties,
  IdentityResolution,
  IdentityResource,
  IdentityResourceCreate,
  IdentityResources,
  Languages,
  ManageUserDeletion,
  Members,
  ModelCreate,
  ModelEdit,
  Models,
  MultiFactorAuth,
  PasswordLess,
  Role,
  Roles,
  SmartEmailing,
  SuspiciousIpThrottling,
  Templates,
  User,
  Users,
} from "../pages";
import EditTemplate from "../pages/consent-menagement/theme-builder/EditTemplate";
import { DetailsView } from "../pages/user-profile/DetailsView";
import { ModelDetailsContextProvider } from "../pages/user-profile/context/ModelDetailsContext";
// import useDomainContext from '../context/DomainContext'
// import { DomainConsentLogs } from "../pages/consent-menagement/DomainConsentLogs";
// import { DomainCookieScan } from "../pages/consent-menagement/DomainCookieScan";
// import { DomainUIBuilder } from "../pages/consent-menagement/DomainUIBuilder";
import { ModelOptions } from "../pages/user-profile/components";
import { premiumFeatureEnabled } from "../utils";

const { admin, appsEditor, usersEditor } = Permission;

export interface RoutesProps extends RouteProps {
  name?: string;
  crumbs?: BreadcrumbsPropsItem[];
  permissions?: Permission[];
}

const did = localStorage.getItem("did");

export const applicationsRoutes: RoutesProps[] = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Home",
    crumbs: [{ name: "Home" }],
    permissions: [admin],
    index: true,
  },

  // Applications
  {
    path: APPS_PATH,
    element: <Applications />,
    name: "Applications",
    crumbs: [{ name: "Applications" }],
    permissions: [admin, appsEditor],
  },

  {
    path: `${APPS_PATH}/:id/*`,
    element: <Application />,
    name: "Applications",
    crumbs: [
      { name: "Applications", to: `${APPS_PATH}` },
      { name: "Application" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${APPS_PATH}/client-secrets/:id`,
    element: <ApplicationSecrets />,
    name: "Applications",
    crumbs: [
      { name: "Applications", to: `${APPS_PATH}` },
      { name: "Application", to: `${APPS_PATH}/:id` },
      { name: "Secret" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${APPS_PATH}/client-properties/:id`,
    element: <ApplicationProperties />,
    name: "Applications",
    crumbs: [
      { name: "Applications", to: `${APPS_PATH}` },
      { name: "Application", to: `${APPS_PATH}/:id` },
      { name: "Client Properties" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${APPS_PATH}/client-claims/:id`,
    element: <ApplicationClaims />,
    name: "Applications",
    crumbs: [
      { name: "Applications", to: `${APPS_PATH}` },
      { name: "Application" },
      { name: "Client Claims" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    ...(premiumFeatureEnabled["userDeletion"] === true && {
      path: `${MANAGE_USER_DATA_DELETION_PATH}/:id`,
      element: <ManageUserDeletion />,
      name: "Applications",
      crumbs: [
        { name: "Applications", to: `${APPS_PATH}` },
        { name: "Application", to: `${APPS_PATH}/:id` },
        { name: "Manage User Deletion" },
      ],
      permissions: [admin],
    }),
  },
  // API Resources
  {
    path: API_RES_PATH,
    element: <ApiResources />,
    name: "API Resources",
    crumbs: [{ name: "API Resources" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_RES_PATH}/create`,
    element: <ApiResourceCreate />,
    name: "API Resource",
    crumbs: [{ name: "API Resources", to: API_RES_PATH }, { name: "Create" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_RES_PATH}/:id`,
    element: <ApiResource />,
    name: "API Resources",
    crumbs: [
      { name: "API Resources", to: `${API_RES_PATH}` },
      { name: "API Resource" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_RES_PATH}/api-secrets/:id`,
    element: <ApiResourceSecrets />,
    name: "API Secrets",
    crumbs: [
      { name: "API Resources", to: `${API_RES_PATH}` },
      { name: "API Secrets" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_RES_PATH}/api-properties/:id`,
    element: <ApiResourceProperties />,
    name: "API Properties",
    crumbs: [
      { name: "API Resources", to: `${API_RES_PATH}` },
      { name: "API Secrets" },
    ],
    permissions: [admin, appsEditor],
  },

  // API Scopes
  {
    path: API_SCOPES_PATH,
    element: <ApiScopes />,
    name: "API Scopes",
    crumbs: [{ name: "API Scopes" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_SCOPES_PATH}/create`,
    element: <ApiScopeCreate />,
    name: "API Scope",
    crumbs: [{ name: "API Scopes", to: API_SCOPES_PATH }, { name: "Create" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_SCOPES_PATH}/:id`,
    element: <ApiScope />,
    name: "API Scope",
    crumbs: [
      { name: "API Scopes", to: `${API_SCOPES_PATH}` },
      { name: "API Scope" },
    ],
    permissions: [admin, appsEditor],
  },
  {
    path: `${API_SCOPES_PATH}/api-scope-properties/:id`,
    element: <ApiScopeProperties />,
    name: "API Scope Properties",
    crumbs: [
      { name: "API Scopes", to: `${API_SCOPES_PATH}` },
      { name: "API Scope Properties" },
    ],
    permissions: [admin, appsEditor],
  },

  // Identity Resources
  {
    path: I_RES_PATH,
    element: <IdentityResources />,
    name: "Identity Resources",
    crumbs: [{ name: "Identity Resources" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${I_RES_PATH}/create`,
    element: <IdentityResourceCreate />,
    name: "Identity Resource",
    crumbs: [
      { name: "Identity Resources", to: I_RES_PATH },
      { name: "Create" },
    ],
    permissions: [admin],
  },
  {
    path: `${I_RES_PATH}/:id`,
    element: <IdentityResource />,
    name: "Identity Resource",
    crumbs: [
      { name: "Identity Resources", to: `${I_RES_PATH}` },
      { name: "Identity Resource" },
    ],
    permissions: [admin],
  },
  {
    path: `${I_RES_PATH}/identity-resource-properties/:id`,
    element: <IdentityRescourceProperties />,
    name: "Identity Resource Properties",
    crumbs: [
      { name: "Identity Resource", to: `${I_RES_PATH}` },
      { name: "Identity Resource Properties" },
    ],
    permissions: [admin],
  },

  // User Management
  {
    path: USERS_PATH,
    element: <Users />,
    name: "Users",
    crumbs: [{ name: "Users" }],
    permissions: [admin, usersEditor],
  },
  {
    path: `${USERS_PATH}/:id/*`,
    element: <User />,
    name: "User",
    crumbs: [{ name: "Users", to: `${USERS_PATH}` }, { name: "User" }],
    permissions: [admin, usersEditor],
    // TODO: we remove Routes from nested components, and replace them like this
    // children: [
    //   {
    //     path: `user-details`,
    //     element: <UserDetails />,
    //   },
    // ],
  },
  {
    path: ROLES_PATH,
    element: <Roles />,
    name: "Roles",
    crumbs: [{ name: "Roles" }],
    permissions: [admin, usersEditor],
  },
  {
    path: `${ROLES_PATH}/:id/*`,
    // path: [`${ROLES_PATH}/:id/settings`, `${ROLES_PATH}/:id/users`],
    element: <Role />,
    name: "Role",
    crumbs: [{ name: "Roles", to: `${ROLES_PATH}` }, { name: "Role" }],
    permissions: [admin, usersEditor],
  },

  // Consent Management
  {
    ...(premiumFeatureEnabled["consent"] === true && {
      path: DOMAINS_PATH,
      element: <Domains />,
      name: "Domains",
      crumbs: [{ name: "Domains" }],
      permissions: [admin],
    }),
  },
  {
    ...(premiumFeatureEnabled["consent"] === true && {
      path: `${DOMAINS_PATH}/:domainId/*`,
      element: <Domain />,
      name: "Domains",
      crumbs: [{ name: "Domains", to: `${DOMAINS_PATH}` }, { name: "Domain" }],
      permissions: [admin],
    }),
  },
  {
    ...(premiumFeatureEnabled["consent"] === true && {
      path: `${UI_BUILDER_PATH}/:templateId/*`,
      // element: <Domain />,
      element: <EditTemplate />,
      // element: <TemplateConfig />,
      name: "UI Builder",
      crumbs: [
        { name: "Domains", to: `${DOMAINS_PATH}` },
        { name: "Domain", to: `${DOMAINS_PATH}/${did}/overview` },
        { name: "UI Builder", to: `${DOMAINS_PATH}/${did}/ui-builder` },
        { name: "Edit" },
      ],
      permissions: [admin],
    }),
  },
  // {
  //   ...(premiumFeatureEnabled["consent"] === true && {
  //     path: `${DOMAINS_PATH}/:id` + CONSENT_LOG,
  //     element: <Domain />,
  //     // element: <DomainConsentLogs />,
  //     name: "Consent Log",
  //     crumbs: [
  //       { name: "Domains" },
  //       { name: "Domain" },
  //       { name: "Consent Log", to: CONSENT_LOG },
  //     ],
  //     permissions: [admin],
  //   }),
  // },
  // {
  //   ...(premiumFeatureEnabled["consent"] === true && {
  //     path: `${DOMAINS_PATH}/:id` + CONSENT_SCAN,
  //     element: <Domain />,
  //     // element: <DomainCookieScan />,
  //     name: "Consent Scan",
  //     crumbs: [
  //       { name: "Domains" },
  //       { name: "Domain" },
  //       { name: "Consent Scan", to: CONSENT_SCAN },
  //     ],
  //     permissions: [admin],
  //   }),
  // },

  // Branding

  {
    path: BRANDINGS_PATH,
    element: <Brandings />,
    name: "Brandings",
    crumbs: [{ name: "Brandings", to: BRANDINGS_PATH }],
    permissions: [admin],
  },
  {
    path: `${BRANDINGS_PATH}/create`,
    element: <BrandingCreate />,
    name: "Brandings",
    crumbs: [{ name: "Brandings", to: BRANDINGS_PATH }, { name: "Create" }],
    permissions: [admin],
  },
  {
    path: `${BRANDINGS_PATH}/:id`,
    element: <Branding />,
    name: "Brandings",
    crumbs: [{ name: "Brandings", to: BRANDINGS_PATH }, { name: "Edit" }],
    permissions: [admin],
  },
  {
    path: `${TEMPLATES_PATH}`,
    element: <Templates />,
    name: "Templates",
    crumbs: [{ name: "Branding" }, { name: "Templates" }],
    permissions: [admin],
  },
  {
    path: `${TEMPLATES_PATH}/:id`,
    element: <CostumizationTemplate />,
    name: "Templates",
    crumbs: [{ name: "Templates", to: TEMPLATES_PATH }, { name: "Edit" }],
    permissions: [admin],
  },
  {
    path: `${GLOBAL_CSS_PATH}/:id`,
    element: <GlobalCSS />,
    name: "Global CSS",
    crumbs: [{ name: "Templates", to: TEMPLATES_PATH }, { name: "Global CSS" }],
    permissions: [admin],
  },

  // Security
  {
    path: ATTACK_PROTECTION_PATH,
    element: <AttackProtection />,
    name: "Security",
    crumbs: [{ name: "Security" }, { name: "Attack Protection" }],
    permissions: [admin],
  },
  {
    path: MULTI_FACTOR_AUTH_PATH,
    element: <MultiFactorAuth />,
    name: "Security",
    crumbs: [{ name: "Security" }, { name: "Multi-factor Auth" }],
    permissions: [admin],
  },

  {
    path: ATTACK_PROTECTION_PATH + BOT_DETECTION_PATH,
    element: <BotDetection />,
    name: "Security",
    crumbs: [
      { name: "Security" },
      { name: "Attack Protection", to: ATTACK_PROTECTION_PATH },
      { name: "Bot Detection" },
    ],
    permissions: [admin],
  },

  {
    path: ATTACK_PROTECTION_PATH + IP_THROTTLING_PATH,
    element: <SuspiciousIpThrottling />,
    name: "Security",
    crumbs: [
      { name: "Security" },
      { name: "Attack Protection", to: ATTACK_PROTECTION_PATH },
      { name: "Suspicius IP Throttling" },
    ],
    permissions: [admin],
  },

  {
    path: ATTACK_PROTECTION_PATH + BRUTE_FORCE_PATH,
    element: <BruteForceProtection />,
    name: "",
    crumbs: [
      { name: "Security" },
      { name: "Attack Protection", to: ATTACK_PROTECTION_PATH },
      { name: "Brute force protection" },
    ],
    permissions: [admin],
  },

  {
    path: ATTACK_PROTECTION_PATH + BREACHED_PASS_PATH,
    element: <BreachPassDetection />,
    name: "",
    crumbs: [
      { name: "Security" },
      { name: "Attack Protection", to: ATTACK_PROTECTION_PATH },
      { name: "Breached password detection" },
    ],
    permissions: [admin],
  },

  // Identity Resolution

  {
    path: I_RESOLUTION_PATH,
    element: <IdentityResolution />,
    name: "",
    crumbs: [{ name: "Identity Resolution" }],
    permissions: [admin],
  },

  // Monitoring
  {
    path: M_AUDIT_LOGS,
    element: <AuditLogs />,
    name: "Audit Logs",
    crumbs: [{ name: "Audit Logs" }],
    permissions: [admin],
  },

  {
    path: M_ERROR_LOGS,
    element: <ErrorLogs />,
    name: "Error Logs",
    crumbs: [{ name: "Error Logs" }],
    permissions: [admin],
  },

  //Settings
  {
    path: SETTINGS_GENERAL_PATH,
    element: <GeneralSettings />,
    name: "General Settings",
    crumbs: [{ name: "Settings" }, { name: "General Settings" }],
    permissions: [admin],
  },
  {
    path: SETTINGS_MEMBER_PATH,
    element: <Members />,
    name: "Members",
    crumbs: [{ name: "Settings" }, { name: "Members" }],
    permissions: [admin],
  },
  {
    path: SETTINGS_LANGUAGES_PATH,
    element: <Languages />,
    name: "Languages",
    crumbs: [{ name: "Settings" }, { name: "Languages" }],
    permissions: [admin],
  },
  {
    path: `${SETTINGS_LANGUAGES_PATH}/:key`,
    element: <EditLanguageKeys />,
    name: "Languages",
    crumbs: [
      { name: "Settings" },
      { name: "Languages", to: SETTINGS_LANGUAGES_PATH },
      { name: "Edit keys" },
    ],
    permissions: [admin],
  },

  // Intergrations - Email Providers
  {
    path: INTEGRATIONS_PATH,
    element: <EmailProviders />,
    name: "Email Providers",
    crumbs: [{ name: "Integrations" }, { name: "Email Providers" }],
    permissions: [admin],
  },
  {
    path: SMART_EMAILING_PATH,
    element: <SmartEmailing />,
    name: "Configure Smart Emailing",
    crumbs: [
      { name: "Integrations" },
      { name: "Email Providers", to: INTEGRATIONS_PATH },
      { name: "Configure Smart Emailing" },
    ],
    permissions: [admin],
  },
  // Authentication Flow
  {
    ...(premiumFeatureEnabled["authFlow"] === true && {
      path: AUTHENTICATION_FLOW,
      element: <AuthFlow />,
      name: "Authentication",
      crumbs: [{ name: "Authentication" }, { name: "Authetication Flow" }],
      permissions: [admin],
    }),
  },
  {
    path: AUTHENTICATION_SETTINGS_PATH,
    element: <AuthSettings />,
    name: "Authentication",
    crumbs: [{ name: "Authentication" }, { name: "Authentication Settings" }],
    permissions: [admin],
  },
  {
    ...(premiumFeatureEnabled["passwordLess"] === true && {
      path: AUTHENTICATION_PASSLESS,
      element: <PasswordLess />,
      name: "Authentication",
      crumbs: [{ name: "Authentication" }, { name: "Passwordless" }],
      permissions: [admin],
    }),
  },
  // User Profile - Models
  {
    path: MODELS_PATH,
    element: <Models />,
    name: "Models",
    crumbs: [{ name: "User Profile" }, { name: "Models" }],
    permissions: [admin],
  },
  {
    path: MODELS_CREATE_PATH,
    element: <ModelCreate />,
    name: "Models",
    crumbs: [{ name: "Models", to: MODELS_PATH }, { name: "Create" }],
    permissions: [admin],
  },
  {
    path: `${MODELS_PATH}/:modelId/*`,
    element: (
      <ModelDetailsContextProvider>
        <DetailsView />
      </ModelDetailsContextProvider>
    ),
    name: "Models Edit",
    crumbs: [{ name: "Models", to: `${MODELS_PATH}` }, { name: "Details" }],
    permissions: [admin, appsEditor],
  },
  {
    path: `${MODELS_PATH}/:modelId/edit`,
    element: <ModelEdit />,
    name: "Models",
    crumbs: [{ name: "Models", to: MODELS_PATH }, { name: "Edit" }],
    permissions: [admin],
  },
  {
    path: `${MODELS_PATH}/:modelId/data-import`,
    element: <DataImport />,
    name: "Models",
    crumbs: [{ name: "Models", to: MODELS_PATH }, { name: "Data Import" }],
    permissions: [admin],
  },
  {
    path: `${MODELS_PATH}/:modelId/model-options`,
    element: (
      <ModelDetailsContextProvider>
        <ModelOptions />
      </ModelDetailsContextProvider>
    ),
    name: "Models",
    crumbs: [{ name: "Models", to: MODELS_PATH }, { name: "Model Options" }],
    permissions: [admin],
  },
];
